import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import validator from 'validator'

const Contact=()=>{
    const [letterClass,setLetterClass]=useState('text-animate');


    const refForm=useRef();
    useEffect(() => {
        const timer = setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 4000)
    
        return () => clearTimeout(timer); // This is the cleanup function
    }, [])
    const sendEmail=(e)=>{
        e.preventDefault()

        emailjs.sendForm('service_cpkekf7','template_up21cvu',refForm.current,'BdhYfFdtRUexwN38L')
        .then(
            () => {
              alert('Message successfully sent!')
              window.location.href='/';
            },
            (error) => {
                alert('Failed to send the message, please try again. Error: ' + JSON.stringify(error));
            }
          )
    }
    return(
        <>
            <div className="container contact-page">
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['C','o','n','t','a','c','t']}
                        idx={15}
                        />
                    </h1>
                    <p className='p'>I am a full-stack web developer with a passion for creating beautiful, responsive,
                    and accessible web applications. I have a strong background in JavaScript,
                    React, and Node.js. I am a quick learner and love to learn new technologies and frameworks.</p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type='text' placeholder='Name'name='name' required/>
                                </li>
                                <li className='half'>
                                    <input type='email' placeholder='Email'name='email'  required/>
                                </li>
                                <li>
                                    <input type='text' placeholder='Subject'name='subject' required/>
                                </li>
                                <li>
                                    <textarea placeholder='Message'name='message' required></textarea>
                                </li>
                                <li>
                                    <input type='submit' className='flat-button' value='SEND'/>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <Loader type='pacman'/>
        </>
    )
}

export default Contact