import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { useEffect, useState } from 'react';
import { faCss3, faGit, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders';


const About = () => {
    const [letterClass,setLetterClass]=useState('text-animate')

    useEffect(() => {
        const timer = setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 3000)
        return () => clearTimeout(timer); // This is the cleanup function
    }, [])

    return(
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['A','b','o','u','t',' ','M','e']}
                    idx={15}
                    />
                </h1>
                <p className='p'>I am a full-stack web developer with a passion for creating beautiful, responsive,
                and accessible web applications. I have a strong background in JavaScript,
                React, and Node.js. I am a quick learner and love to learn new technologies and frameworks.</p>
                <p className='p'>I am quite confident,naturally curious and prepetually working on improving myself.</p>
                <p className='p'>My goal is to create a positive impact on the world by creating innovative products and services.</p>
            </div>
            <div className='stage-cube-cont'>
                <div className='cube-spinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faHtml5} color='#F06529'/>
                        </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faCss3} color='#28A4D9'/>
                        </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faReact} color='#5ED4F4'/>
                        </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faJsSquare} color='#EFD81D'/>
                        </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faGitAlt} color='#EC4'/>
                        </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faGit} color='#DD0031'/>
                        </div>
                </div>
            </div>
        </div>
        <Loader type='pacman'/>
        </>
    )
}

export default About;