import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-s.png'
import Logo from './Logo'
import './index.scss'

const Home=()=>{
    const [letterClass,setLetterClass]=useState('text-animate');
    const nameArray=['a','i','m'];
    const jobArray=['a',' ','e','l','e','c','t','r','i','c','a','l',' ','e','n','g','i','n','e','e','r'];
    useEffect(() => {
      const timer = setTimeout(() => {
        setLetterClass('text-animate-hover')
      }, 4000)
  
      return () => clearTimeout(timer); // This is the cleanup function
  }, [])
    
      return (
        <>
          <div className="container home-page">
            <div className="text-zone">
              <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i,</span>
                <br />
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>
                <img
                  src={LogoTitle}
                  alt="JavaScript Developer Name, Web Developer Name"
                />
                <AnimatedLetters
                  letterClass={letterClass}
                  strArray={nameArray}
                  idx={15}
                />
                <br />
                <AnimatedLetters
                  letterClass={letterClass}
                  strArray={jobArray}
                  idx={22}
                />
              </h1>
              <h2>web Developement / machine Learning / CCN</h2>
              <Link to="/contact" className="flat-button">
                CONTACT ME
              </Link>
            </div>
            
          </div>
          <Logo/>
          <Loader type="pacman" />
        </>
      )
    }
    
    export default Home