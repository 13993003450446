import { Link ,NavLink} from 'react-router-dom';
import './index.scss';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LogoS from '../../assets/images/logo-s.png';
import LogoSubtitle from '../../assets/images/logo_sub.svg';
import {faEnvelope, faHome,faUser,faBars} from '@fortawesome/free-solid-svg-icons'
import {faGithub,faLinkedin} from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => {
  const [show,setshow] = useState(false);
  return (
    <div className="nav-bar">
      <Link to="/" className='logo'>
        <img src={LogoS} alt='Logo'/>
        <img src={LogoSubtitle} alt='saim' className='sub-logo'/>
      </Link>
      <div onClick={()=>setshow(!show)}>
      <FontAwesomeIcon icon={faBars} color='#4d4d4e' className={show?'clicked':'bars'}/></div>
      <div className={show?'nav-bar-mobile':'dont'}>
      <NavLink to='/' className='link' onClick={()=>setshow(!show)}>
          <FontAwesomeIcon icon={faHome} color='#4d4d4e'className='icon'/>Home
        </NavLink>
        <NavLink to='/about' className='link' onClick={()=>setshow(!show)} >
          <FontAwesomeIcon icon={faUser} color='#4d4d4e'className='icon'/>About
        </NavLink>
        <NavLink to='/contact' className='link' onClick={()=>setshow(!show)}>
          <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' className='icon'/>Contact
        </NavLink>
      </div>
      <nav>
        <NavLink to="/" exact='true' activeclassname='active' >
          <FontAwesomeIcon icon={faHome} color='#4d4d4e'/>
        </NavLink>
        <NavLink to="/about" exact='true' activeclassname='active' className='about-link'>
          <FontAwesomeIcon icon={faUser} color='#4d4d4e'/>
        </NavLink>
        <NavLink to="/contact" exact='true' activeclassname='active' className='contact-link'>
          <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e'/>
        </NavLink>
      </nav>
      <ul>
        <li>
          <a target='_blank' 
          rel='noreferer' 
          href='https://www.linkedin.com/in/muhammad-saim-mushtaq-9b6148267'>
            <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e'/>
          </a>
        </li>
        <li>
          <a target='_blank' 
          rel='noreferer' 
          href='https://github.com/MuhammadSaimMushtaq'>
            <FontAwesomeIcon icon={faGithub} color='#4d4d4e'/>
          </a>
        </li>
      </ul>
    </div>
  );
}
export default Sidebar;